<template>
  <div class="index" v-if="shoeW">
    <heade currentrStr=""></heade>
    <!--<router-view/> -->
    <div class="banner">
      <!---calc(50% + 283px) -64px;-->
      <!---
        <div
        class="banner-left common-width bannerAdd"
        :style="`  background-image: url(${banner});background-position:${
          parentNew == '数据库系统大赛' ||
          parentNew == '操作系统大赛' ||
          parentNew == '编译系统大赛'
            ? ' calc(50% + 272px) 76px'
            : 'calc(50% + 272px) 15px'
        };
        background-size:${
          parentNew == '数据库系统大赛' ||
          parentNew == '操作系统大赛' ||
          parentNew == '编译系统大赛'
            ? ''
            : '45%'
        }

        `"
      >
      -->
      <div
        class="banner-left common-width bannerAdd"
        :style="`display:flex; flex-warp:warp; background-image: url(${banner});background-position:${
          parentNew == '操作系统大赛' || parentNew =='编译系统大赛' || parentNew == '数据库系统大赛'
            ? ' calc(50% + 272px) 76px'
            : 'calc(50% + 272px) 15px'
        };
        background-size:${parentNew == '操作系统大赛' || parentNew == '编译系统大赛'|| parentNew == '数据库系统大赛' ? '' : '45%'}

        `"
      >
      <div>
        <p class="banner-title">{{ title }}</p>
        <p class="banner-detail">{{ stitle }}</p>
        <p style="margin-bottom: 5px; color: #ff7d00; font-weight: bold" v-if="count!=''">
          参赛队伍数量：{{ count }}
        </p>
        <p>
          <!-- <a-button type="primary" @click="look('./signUp')"> 去报名 </a-button> -->

          <span v-if="cardS">
            <a-button
              type="primary"
              @click="look('./signUp')"
              v-if="status == '报名已开始'"
              >开始报名
            </a-button>
            <a-button type="primary" disabled v-else-if="status!=''" > {{ status }} </a-button>
            <a-button
              style="margin-left: 10px"
              type="danger"
              @click="into('access')"
              v-if="access == '打开'"
              >进入训练赛
            </a-button>
            <a-button
              style="margin-left: 10px"
              type="danger"
              @click="into('b_access')"
              v-if="b_access == '打开'"
              >进入{{ whereS }}
            </a-button>
            <a-button
              style="margin-left: 10px"
              type="danger"
              @click="into('f_access')"
              v-if="f_access == '打开'"
              >进入{{ whereR }}
            </a-button>
            <!-- <br v-if="typeP" /> -->
          </span>

          <a-button
          type="primary" plain
            ghost
            style="margin-top: 10px; margin-left: 10px"
            v-if="PB"
            @click="sjkTo('https://tianchi.aliyun.com/specials/promotion/2024-csdcc-polardb')"
       
         
          >
          报名/提交</a-button
          >
          <a-button
          type="primary" plain
            ghost
            style="margin-top: 10px; margin-left: 10px"
            v-if="OceanBaseB"
            @click="sjkTo('https://open.oceanbase.com/competition?joinSource=baidu_PC_001343')"
       
         
          >
          报名/提交</a-button
          >
          <a-button
          type="primary" plain
            ghost
            style="margin-top: 10px; margin-left: 10px"
            v-if="f_access_regurl!=''"
            @click="sjkTo(f_access_regurl)"
       
         
          >
            决赛报名</a-button
          >
          
          <a-button
            type="danger"
            ghost
            style="margin-top: 10px; margin-left: 10px"
            @click="goTo"
            v-if="typeP"
          >
            赛题</a-button
          >
          <!-- <a-button
          type="primary" plain
            ghost
            style="margin-top: 10px; margin-left: 10px"
            @click="sjkTo('https://doc.weixin.qq.com/forms/AIsAZwc5AAwAPUAzgYtADsbSXMcRNjSzf?page=1')"
            v-if="this.xmds=='操作系统大赛'"
         
          >
            决赛报名</a-button
          > -->
          <!-- <a-button
          type="primary" plain
            ghost
            style="margin-top: 10px; margin-left: 10px"
            @click="sjkTo('https://doc.weixin.qq.com/forms/AIsAZwc5AAwAPUAzgYtADsCW67HY54Rff')"
            v-if="this.xmds=='编译系统大赛'"
         
          >
            决赛报名</a-button
          > -->
          
          <!-- <a-button
            type="primary"
            ghost
            style="margin-top: 10px;margin-left: 10px"
            @click="goToW"
            v-if="parentNew=='操作系统大赛' || parentNew=='数据库系统大赛' || parentNew == '编译系统大赛'"
       
          >
          决赛报名</a-button
          > -->
        </p>
      </div>
      <div >
        <!-- <div   class="xmvideo">
          <video
        v-if="xmds=='小米大赛'"
      
        
                  controls="controls"
                  width="65%"
                  x5-playsinline
                  webkit-playsinline="true"
                  playsinline="true"
          
                  muted="muted"
                  style="cursor: pointer; float:right; display: inherit;margin:-80px 30px 0 0 "
                >
                  <source src="../images/video(14).mp4" type="video/mp4" />
                </video>
        </div> -->
       
        <a-button
          type="danger"
          style="position: absolute; right: 0; bottom: 0; z-index: 1000"
          @click="zdjsFun"
          v-if="zdjs"
        >
          指导教师入口
        </a-button>
        <a-button
          type="primary"
          style="position: absolute; right: 140px; bottom: 0; z-index: 1000"
          @click="fxFun"
        >
          分享大赛
        </a-button>
      </div>
        <!-- </div><el-button type="success" style="    position: absolute;right: 100px;bottom: 0;z-index:1000" icon="el-icon-share" size="">分享大赛</el-button>       -->
        <a-modal
          v-model="visibleJS"
          title="指导教师入口"
          @ok="handleOk"
          width="70%"
        >
          <p>
            指导教师QQ群：<a :href="qqD" :title="qq">{{ qq }}</a
            >【仅限 高校教师，请注明：学校+姓名】，获取观赛账号了解大赛信息<span
              v-if="ping"
              >,平台开课</span
            >
          </p>
        </a-modal>
      </div>
    </div>
    <div class="common-width" v-if="info.length > 0" style="padding: 0 20px">
      <a-steps
        :current="nodenumber"
        style="text-align: left; margin-top: 20px"
        direction="horizontal"
        size="small"
      >
        <a-step
          :title="item.title"
          :description="item.time"
          v-for="(item, index) in info"
          :key="index"
        />
      </a-steps>
      <!-- <el-steps :active="nodenumber" align-center style='text-align:left;margin-top:20px;' finish-status="success" >
   <el-popover slot="progressDot" slot-scope="{ index, status, prefixCls }">
        <template slot="content">
          <span>step {{ index }} status: {{ status }}</span>
        </template>
        <span :class="`${prefixCls}-icon-dot`" />
      </el-popover>
  <el-step :title="item.title" :description="item.time"  v-for="(item,index) in info" :key='index'></el-step>

</el-steps> -->
    </div>
    <!--新闻-->

    <!-- <swiper :news="news"></swiper> -->
    <sp :news="news" :title="name"></sp>
    <div ref="scrollRef" id="scrollRef">
      <a-divider orientation="center" class="hr">
        {{ stitle }}
      </a-divider>
    </div>
    <tabDs
      style="padding-bottom: 10px; border-bottom: 7px solid #1e80ff"
      :getData="getData"
      :activeNamePr="activeName"
      @activeNameFun="activeNameFun"
    ></tabDs>
    <!-- <tabDsCzGn v-if="type == 'xitong' && fontType" style='padding-bottom:10px;border-bottom: 7px solid #1e80ff;'></tabDsCzGn>
    <tabDsCzNh v-if="type == 'xitong' && !fontType" style='padding-bottom:10px;border-bottom: 7px solid #1e80ff;'></tabDsCzNh> -->
    <!-- <div class="box">
      <p class="box-title">{{ title }}</p>
      <p class="box-detail">
        {{comment}}
      </p>

    </div> -->
    <!-- <div class="video" :class='muted==""?"mutedBG":""'>
    <i class="el-icon-video-play kong"  v-if='muted == ""'  style='color: #fff; position:absolute;cursor: pointer;margin-left:-50px;left:50%;top:50%;'></i>

    <video  controls  width='55%'   @click='picFun' :class="muted ==''?'muted':'mutedShow'" style='cursor: pointer'>
      <source src="../images/226_1667181317.mp4" autoplay type="video/mp4" >
    </video>

    </div>  -->

    <!-- <div class="box bottom common-width">
 
      <div style="margin-top: 30px">
        <p class="banner-title title-w">
          特别福利<a-icon
            type="fire"
            theme="twoTone"
            two-tone-color="#ff0000"
            style="margin-left: 5px"
          />
        </p>
        <p class="banner-detail title-w title-w-p">华为绿卡来啦！</p>
        <p class="banner-detail title-w title-w-p">
          华为公司对于参加本竞赛，获得本竞赛决赛三等奖以上的同学可申请校园招聘绿卡，获得校招优先权哦！！！
        </p>
        <p>

        </p>

        <p class="banner-detail title-w title-w-p">欢迎大家踊跃报名！！！</p>
      </div>
    </div>

    <div class="card-grid common-width tb">
      <a-card title="1、登录华为招聘官网career.huawei.com" style="width: 300px">
        <p style="padding: 0 10px 5px">
          <a
            slot="extra"
            href="https://career.huawei.com/reccampportal/portal5/index.html"
            target="_blank"
            style="font-size: 12px"
            >进入</a
          >
        </p>
      </a-card>
      <a-card title="2、进入校园招聘专区，选择研发类岗位" style="width: 300px">
      </a-card>
      <a-card
        title="3、选择您的意向岗位，且第一意向部门选择2012实验室中央软件院"
        style="width: 300px"
      >
      </a-card>
      <a-card
        title="4、如果期望直接联系招聘HR了解具体岗位方向，可以以【简历编号+姓名+岗位】命名邮箱发送：yaoxiaoping@huawei.com"
        style="width: 300px"
      >
      </a-card>
    </div> -->
    <!-- <div class="box bottom common-width news" v-if="type == 'bianyi'">

      <div>
        <p class="banner-title title-w">
          特别福利<a-icon
            type="fire"
            theme="twoTone"
            two-tone-color="#ff0000"
            style="margin-left: 5px"
          />
        </p>
        <p class="banner-detail title-w title-w-p">华为绿卡来啦！</p>
        <p class="banner-detail title-w title-w-p">
          华为公司对于参加本竞赛，获得本竞赛决赛三等奖以上的同学可申请校园招聘绿卡，获得校招优先权哦！！！
        </p>
        <p class="banner-detail title-w">
          1、登录华为招聘官网
          <a
            target="_blank"
            href="https://career.huawei.com/reccampportal/portal5/index.html"
            >career.huawei.com</a
          >
        </p>
        <p class="banner-detail title-w">2、进入校园招聘专区，选择研发类岗位</p>
        <p class="banner-detail title-w">
          3、选择您的意向岗位，且第一意向部门选择2012实验室中央软件院
        </p>
        <p class="banner-detail title-w">
          4、如果期望直接联系招聘HR了解具体岗位方向，可以以【简历编号+姓名+岗位】命名邮箱发送：yaoxiaoping@huawei.com
        </p>
      </div>
    </div> -->

    <!-- <reg
      :visible="visible"
      :visibleIphon="visibleIphon"
      :typePran="1"
      @regFun="regFun(arguments)"
      v-if="visible == true"
    >
    </reg> -->
    <a-back-top />
    <!---龙芯个人还是团体-->
    <el-dialog title="请选择参赛类型" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-radio v-model="saiValue" label="团队赛" border>团队赛</el-radio>
        <el-radio v-model="saiValue" label="个人赛" border>个人赛</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="saiFun()" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import heade from "@/components/header.vue";
// import reg from "@/components/reg.vue";
import tabDs from "@/components/tabDs.vue";
// import swiper from "@/components/swiper.vue";
import sp from "@/components/sp.vue";
// import tabDsCzGn from "@/components/tabDsCzGn.vue";
// import tabDsCzNh from "@/components/tabDsCzNh.vue";

export default {
  data() {
    return {
      shoeW:false,
      zdjs: false,
      saiValue: "",
      dialogVisible: false,
      typeStyle: "",
      qq: "",
      qqD: "",
      visibleJS: false,
      activeName: "1",
      cardS: false,
      count: 0,
      typeP: false,
      jsbm: false,
      whereS: "初赛",
      whereR: "决赛",
      muted: "",
      type: null,
      news: [],
      name: "",
      visible: false,
      visibleIphon: false,
      parent: "",
      comment: "",
      title: "",
      status: "",
      testperiod: "",
      banner: require("../images/shujuku000.png"),
      stitle: "",
      fontType: null,
      access: "",
      b_access: "",
      f_access: "",
      f_access_regurl:'',
      intoId: "",
      info: [],
      nodenumber: 0,
      getData: [],
      parentNew: "",
      curId: "",
      ping: true,
      xmds:'',
      PB:false,
      OceanBaseB:false

      // anjing-tokenName:false
    };
  },
  components: {
    heade,
    // reg,
    tabDs,
    // swiper,
    sp,
    // tabDsCzGn,
    // tabDsCzNh,
  },

  mounted() {
 
    if (window.location.href.indexOf("?TYPE") != -1) {
    if(this.$route.query.TYPE == 'IS_I'){// 小米
      window.localStorage.setItem("anjing-parent",  '小米大赛');
      this.xmds='小米大赛'
     // this.banner=''
    }


    
    else {
   this.xmds =window.localStorage.getItem("anjing-parent");
    if(this.xmds == '小米大赛'){
     // this.banner=''
    }
    }

     if(this.$route.query.TYPE == 'PB'){
        this.PB = true
    }
    if(this.$route.query.TYPE == 'OB'){
        this.OceanBaseB = true
    }
    


    }
    setTimeout(() => {
      this.shoeW = true
    }, 100);


    //     if(window.location.href.indexOf('?TYPE=DB')!=-1){// 说明是分享的数据库连接 那就要默认id和title
    //       this.name = '2023全国大学生计算机系统能力大赛赛编译系统挑战赛';
    //       this.curId = '63eb455d6cdc564ea96d520a'
    //     }
    //     else if(window.location.href.indexOf('?TYPE=OS_N')!=-1){//说明是分享的操作系统内核 那就要默认id和title
    //       this.name = '2023全国大学生计算机系统能力大赛操作系统设计赛-内核实现赛';
    //       this.curId = '63eb51faa3a2834804263a15'
    //     }
    //     else if(window.location.href.indexOf('?TYPE=OS_G')!=-1){//说明是分享的操作系统功能 那就要默认id和title
    //       this.name = '2023全国大学生计算机系统能力大赛操作系统设计赛-功能挑战赛';
    //       this.curId = '63eb51fda3a2834804263a16'
    // }
    // else if(window.location.href.indexOf('?TYPE=CO_S')!=-1){//说明是分享的编译设计 那就要默认id和title
    //   this.name = '2023全国大学生计算机系统能力大赛编译系统设计赛';
    //       this.curId = '63eb51b8a3a2834804263a07'
    // }
    // else if(window.location.href.indexOf('?TYPE=CO_T')!=-1){//说明是分享的编译挑战 那就要默认id和title
    //   this.name = '2023全国大学生计算机系统能力大赛编译系统挑战赛';
    //       this.curId = '640847a5ae05d248b9414cc8'
    // }
    // else if(window.location.href.indexOf('?TYPE=CO_M')!=-1){//说明是分享的编译挑战 那就要默认id和title
    //   this.name = '全国大学生计算机系统能力大赛-智能系统创新大赛';
    //       this.curId = '658b8fb09b45705efe0fc7f2'
    // }
    if (window.location.href.indexOf("?TYPE") != -1) {
      console.log(this.$route.query.TYPE);
      this.getType(this.$route.query.TYPE);
      // _this.title.indexOf("功能挑战赛") != -1
      //         ? (_this.typeP = true)
      //         : (_this.typeP = false);
      if (this.$route.query.TYPE == "OS_G"|| this.$route.query.TYPE == "OS_XB" ) {
        this.typeP = true;
      } else {
        this.typeP = false;
      }
    } else {
      this.name = window.localStorage.getItem("anjing-name");
      this.curId = window.localStorage.getItem("anjing-home_id");
      this.typeStyle = window.localStorage.getItem("anjing-urltype");
      if (this.typeStyle == "OS_G" || this.typeStyle == "OS_XB") {
        this.typeP = true;
      } else {
        this.typeP = false;
      }
      this.init();
    }
    this.cardS =
      window.localStorage.getItem("anjing-roleName") == "教师" ||
      window.localStorage.getItem("anjing-roleName") == "企业"
        ? false
        : true;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    console.log(document.body.scrollTop);
    debugger;

    // else {
    //   this.banner = require('../images/bianyi000.png')
    // }
    // if( window.localStorage.getItem("anjing-img")== 2){
    //   this.banner='/img/WechatIMG27050.7ca1df4f.png'
    // }
  },
  watch:{
    shoeW(){
      this.getType(this.$route.query.TYPE);
    }
  },
  methods: {
    saiFun() {
      this.dialogVisible = false;
      window.localStorage.setItem("anjing-longxin", this.saiValue);

      this.$router.push({ path: "./signUp" });
    },
    getType(type) {
      let data = {
        op: "getmatch",
        type: type,
        // title: title,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.name = response.data.data.title;
            _this.curId = response.data.data._id;
            _this.typeStyle = response.data.data.urltype;
            setTimeout(() => {
      if (
        _this.name.indexOf("西北") != -1
      ) {
        _this.whereS = "区域赛"; // "区域赛";
      }

      if (
        _this.name.indexOf("龙芯") != -1 || _this.typeStyle == 'OB' || _this.typeStyle == 'PB'
      ) {
  
        _this.zdjs = false;
      }
      else {
        _this.zdjs = true;
      }
    }, 100);
            console.log(response.data);
            _this.init();
          } else {
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          _this.$message.error("接口数据异常");
          console.log(error);
        });
    },

    fxFun() {
      // if (this.name == "2023全国大学生计算机系统能力大赛数据库系统设计赛") {
      //   this.onCopy("/#/index?TYPE=DB");
      // } else if (
      //   this.name == "2023全国大学生计算机系统能力大赛操作系统设计赛-内核实现赛"
      // ) {
      //   this.onCopy("/#/index?TYPE=OS_N");
      // } else if (
      //   this.name == "2023全国大学生计算机系统能力大赛操作系统设计赛-功能挑战赛"
      // ) {
      //   this.onCopy("/#/index?TYPE=OS_G");
      // } else if (
      //   this.name == "2023全国大学生计算机系统能力大赛编译系统设计赛"
      // ) {
      //   this.onCopy("/#/index?TYPE=CO_S");
      // } else if (
      //   this.name == "2023全国大学生计算机系统能力大赛编译系统挑战赛"
      // ) {
      //   this.onCopy("/#/index?TYPE=CO_T");
      // } else if (this.name == "全国大学生计算机系统能力大赛-智能系统创新大赛") {
      //   this.onCopy("/#/index?TYPE=CO_M");
      // }
      this.onCopy(`/#/index?TYPE=${this.typeStyle}`);
    },
    onCopy(urlC) {
      const url = window.location.href.split("/#/index")[0] + urlC;
      console.log(console.log(url));
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功:" + window.location.href.split("/#/index")[0] + urlC,
        type: "success",
      });
      oInput.remove();
    },
    initTab(title) {
      let data = {
        op: "tablist",
        title: title,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/news.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // _this.$router.push({ path:response.data.data });
            // window.location.href= response.data.data
            _this.getData = response.data.data;
            console.log(_this.getData);
            // console.log(response.data.data.parent);
          } else {
            // _this.$message.info(response.data.msg);
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          _this.$message.error("接口数据异常");
          console.log(error);
        });
    },
    into(type) {
      let data = {
        op: "entry",
        _id: this.intoId,
        type: type,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/signup.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // _this.$router.push({ path:response.data.data });
            window.location.href = response.data.data;

            console.log(response.data.data.parent);
          } else if (response.data.value == "sessionerror") {
            // 未登录
            _this.$message.info(response.data.msg);

            setTimeout(() => {
              _this.$router.push({
                path: "./login-reg-pass",
                query: { url: "./index" },
              });
            }, 800);
          } else {
            // _this.$message.info(response.data.msg);
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          _this.$message.error("接口数据异常");
          console.log(error);
        });
    },
    goTo() {
      this.$router.push({ path: "./sList", query: { TYPE: this.typeStyle } });
    },
    sjkTo(url){
      window.open(url)
    },
    goToW() {
      if (this.parentNew == "数据库系统大赛") {
        window.open(
          "https://doc.weixin.qq.com/forms/AIsAZwc5AAwAPUAzgYtADsR1tp7LlHQlf"
        );
      }
      if (this.parentNew == "操作系统大赛") {
        window.open(
          "https://doc.weixin.qq.com/forms/AIsAZwc5AAwAPUAzgYtADslu3tqS7iRSf"
        );
      }
      if (this.parentNew == "编译系统大赛") {
        window.open(
          "https://doc.weixin.qq.com/forms/AIsAZwc5AAwAPUAzgYtADsV1rThf0ZRWf"
        );
      }
    },
    picFun() {
      this.muted = "muted";
    },

    newsFun(title) {
      let data = {
        op: "indexnews",
        title: title,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/news.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.news = response.data.data;
          } else {
            // _this.$message.info(response.data.msg);
            console.log(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    to(url) {
      this.$router.push({ path: url });
    },

    init() {
      let data = {
        op: "matchinfo",
        _id: this.curId,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.localStorage.setItem("anjing-parent",  response.data.data.parent);
            _this.count = response.data.data.count;
            _this.access = response.data.data.access;
            _this.b_access = response.data.data.b_access;
            _this.f_access = response.data.data.f_access;
            _this.f_access_regurl=response.data.data.f_access_regurl;
            _this.intoId = response.data.data._id;
            _this.parent = "2022全国大学生计算机系统能力大赛"; //response.data.data.parent;
            _this.comment = response.data.data.comment;
            _this.title = response.data.data.title;
            _this.newsFun(_this.title);
            _this.initTab(_this.title);
            _this.status = response.data.data.status;
            _this.testperiod = response.data.data.testperiod;
            _this.stitle = response.data.data.stitle;
            if (response.data.data.nodeinfo) {
              _this.info = response.data.data.nodeinfo;
            }
            window.localStorage.setItem(
              "anjing-content",
              response.data.data.content
            );
            _this.nodenumber = response.data.data.nodenumber;
            debugger;
            window.localStorage.setItem("anjing-stitleNew", _this.title);
       

            // _this.title.indexOf("功能挑战赛") != -1
            //   ? (_this.typeP = true)
            //   : (_this.typeP = false);
            _this.fontType =
              response.data.data.title.indexOf("功能挑战赛") != -1
                ? true
                : false;

            console.log(response.data.data.parent);
            if (
              window.localStorage.getItem("anjing-parent") == "编译系统大赛" ||
              (window.localStorage.getItem("anjing-parent") == "模拟赛" &&
                window.localStorage
                  .getItem("anjing-stitleNew")
                  .indexOf("编译系统") != -1)
            ) {
              // 编辑赛
              if (window.localStorage.getItem("anjing-parent") == "模拟赛") {
                _this.whereS = "模拟赛";
              } else {
                _this.whereS = "初赛";
              }
              _this.type = "bianyi";
              _this.parentNew = "编译系统大赛";
              //  一一对应banner
              _this.banner = require("../assets/WechatIMG18516.jpg");
              _this.qq = "719489238";
              _this.qqD =
                "https://qm.qq.com/cgi-bin/qm/qr?k=Q6SgrbBJHkBW60TJaYPzCNTOzRnZ-hf1&authKey=4lMK%2FNf2Qv42%2B%2BejxcA1SlezR4Z3Y8%2FbTK6mqXD4IdEOQcINq%2FopXg%3D%3D&group_code=719489238";
            } else {
              _this.type = "xitong";
            }
            debugger;
            console.log(
              window.localStorage.getItem("anjing-parent") == "操作系统大赛"
            );
            if (
              window.localStorage.getItem("anjing-parent") == "数据库系统大赛"
            ) {
              _this.parentNew = "数据库系统大赛";
              //  一一对应banner
              _this.banner = require("../assets/WechatIMG18668.jpg");
              _this.qq = "344172263";
              _this.qqD =
                "https://qm.qq.com/cgi-bin/qm/qr?k=Pcjter98Q6JXv2bUGpttNgsEixI9lL5r&authKey=sW4TzfI%2FoqCcfS5HLTjSkrPnFiRaAxoXkKjyFLQhIyz6OmlTyWCXmFOf%2FeyboM3g&noverify=0&group_code=344172263";
            } else if (
              (window.localStorage.getItem("anjing-parent") == "操作系统大赛" &&
                window.localStorage
                  .getItem("anjing-stitleNew")
                  .indexOf("西北") == -1) ||
              (window.localStorage.getItem("anjing-parent") == "模拟赛" &&
                window.localStorage
                  .getItem("anjing-stitleNew")
                  .indexOf("操作系统") != -1)
            ) {
              console.log(
                window.localStorage.getItem("anjing-parent") == "操作系统大赛"
              );
              _this.qq = "658157307";
              _this.qqD =
                "https://qm.qq.com/cgi-bin/qm/qr?k=nB7YafmJ4JncOTKkDyzkaJfaP78N8nB9&authKey=MbeElxjVwJ4ewldKM0V15ywQ2GPBVHBnjE6sgSEO07H1bRqb7vKp3QpvYchYWN%2FD&noverify=0&group_code=658157307";
              if (window.localStorage.getItem("anjing-parent") == "模拟赛") {
                _this.whereS = "模拟赛";
              } else {
                _this.whereS = "初赛"; // "区域赛";
              }

              _this.whereR = "决赛";
              _this.parentNew = "操作系统大赛";
              //

              //  一一对应banner
              // _this.banner = require("../images/123456.jpeg");
              _this.banner = require("../images/WechatIMG15600.jpg");
            }
          } else {
            // _this.$message.info(response.data.msg);
            console.log(response.data.msg);
            _this.$router.push({ path: "/" });
          }
          if (window.localStorage.getItem("anjing-parent") == "小米大赛") {
            _this.qq = "762227246";
            _this.ping = false;
          } else {
            _this.ping = true;
          }
          if (window.localStorage.getItem("anjing-parent") == "oceanbase大赛") {
            _this.banner = require('../images/WechatIMG23840.jpg')
          } else {
            // _this.ping = true;
          }
        })
        .catch(function (error) {
          _this.$message.error("接口数据异常");
          console.log(error);
        });
    },

    look(URL) {
      let data = {
        op: "checkreg",
        project: window.localStorage.getItem("anjing-name"),
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/signup.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // 可以去报名
            window.localStorage.setItem("anjing-info", "");
            if (
              window.localStorage.getItem("anjing-stitleNew").indexOf("龙芯") !=
              -1
            ) {
              // 龙芯要做处理，团队还是个人
              _this.dialogVisible = true;
            } else {
              window.localStorage.setItem("anjing-longxin", undefined);

              _this.$router.push({ path: URL });
            }
          } else if (response.data.value == "sessionerror") {
            // 未登录
            _this.$message.info(response.data.msg);

            setTimeout(() => {
              _this.$router.push({
                path: "./login-reg-pass",
                query: { url: "./index" },
              });
            }, 800);
          } else {
            _this.$message.info(response.data.msg);
            setTimeout(() => {
              _this.$router.push({
                path: "./enroll",
              });
            }, 1500);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    regFun(val) {
      this.visible = val[0];
      if (val[1]) {
        this.loginOk = true;
        // this.anjing-tokenName = true
        this.user = window.localStorage.getItem("anjing-tokenName");
        location.reload();
      }
    },
    zdjsFun() {
      this.visibleJS = true;
      // this.activeName = '7';
      // a.scrollTop = 0
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    activeNameFun(val) {
      this.activeName = val;
      console.log(this.activeName);
    },
  },
};
</script>
<style scoped>
div,
p,
ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  /* background: url(../images/WechatIMG27050.png); */
  background-position: calc(50% + 400px) 5px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: left;
  padding: 20px;
  text-align: left;
}
.bannerAdd {
  background-position: calc(50% + 272px) 76px;
  background-size: 55%;
  background-repeat: no-repeat;
}
.banner-title {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  width: 400px;
}
.banner-left {
  /* width: 1240px; */
  padding-top: 100px;
  position: relative;
  /* margin: 0 auto; */
}
.banner-detail {
  /* width: 400px; */
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;

  padding: 20px 0 5px;
}
.box-title {
  font-size: 30px;
  /* font-weight: 400; */
  color: #000;
}
.box-detail {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  padding: 20px 0;
}
.hr {
  font-size: 40px;
  font-weight: bold;
  /* margin:0; */
  background: #fff;
  margin: 0 0 20px;
}
/deep/ .ant-divider-with-text-center::before,
/deep/.ant-divider-with-text-center::after {
  border-top: 7px solid #1e80ff;
}
.box {
  /* max-width: 1240px;
  margin: 0 auto; */
  text-align: left;
}
.video {
  position: relative;
  max-height: 600px;
  overflow: hidden;
  background: #000;
  /* background: url(../images/WechatIMG27958.png) no-repeat; */
  background-size: cover;
  margin: 20px 0 0;
}
.bottom {
  display: flex;
  justify-content: space-between;
}
.bottom-pic {
  width: 28%;
}
.title-w {
  /* width: 600px; */
}
.card-grid {
  /* max-width: 1240px; */
  margin: 5px auto !important;
  display: flex;
  /* justify-content: flex-start; */
  flex-wrap: wrap;
}
.card-grid div {
  margin: 10px 0.2% !important;
  margin-bottom: 15px;
  width: 24.6%;
}
.card-title {
  font-size: 30px;
  font-weight: bold;
  color: #1e80ff;
}
.index {
  padding-bottom: 20px;
}
/deep/ .ant-card-head {
  padding: 0 5px;
  border-bottom: 0;
}
/deep/ .ant-card-bordered {
  border-top: 2px solid #01c7ff;
  padding: 10px 0 !important;
  background: #f5fcff;
}
/deep/ .ant-card-head-title {
  font-size: 12px;
  padding: 10px 0;
}
/deep/ .ant-card-head {
  min-height: 38px;
}
/deep/ .el-tabs__content {
  min-height: 500px;
}
/deep/ .ant-card-bordered:hover {
  box-shadow: 1px 4px 4px rgb(233, 233, 233);
}
.tb /deep/ .ant-card-bordered:hover {
  box-shadow: 0px 0px 0px #fff;
}
.tb /deep/ .ant-card-bordered {
  border-top: 2px solid #fe5900;
  padding: 10px 0 !important;
  background: #fffafc;
}
.tb /deep/.ant-card-head-title {
  white-space: inherit;
  padding: 0 !important;
  text-align: left;
}
/deep/ .ant-card-body {
  padding: 0;
  text-align: right;
}
/deep/ .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  width: 14px;
  height: 14px;
}
/deep/
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
/deep/
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: #1890ff;
  font-weight: bold;
}
/deep/ .ant-steps-item-title {
  white-space: break-spaces;
}
/deep/
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item-description {
  max-width: 200px;
}
/deep/.ant-modal-footer {
  display: none;
}
.title-w-p {
  padding: 0;
}
.news {
  margin-top: 30px !important;
}
.news .title-w {
  padding: 5px 0;
}
.muted {
  opacity: 0;
}
.mutedBG {
  /* background:url('../images/WechatIMG263.jpeg') no-repeat;
  background-size:100% */
}
.mutedShow {
  opacity: 1;
}
.kong {
  font-size: 150px;
  margin-top: -75px;
}
/deep/
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
/deep/
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.9);
}
/deep/ .ant-steps-item-title {
  word-break: keep-all;
}
@media (max-width: 1500px) {
  .bannerAdd {
    background-position: calc(50% + 272px) 76px;
    background-size: 53%;
  }
}
@media (max-width: 1200px) {
  .hr {
    font-size: 20px;
  }
  .card-grid {
    padding: 0 10px;
  }
  .bannerAdd {
    background-position: calc(50% + 272px) 76px;
    background-size: 50%;
  }

  .box {
    padding: 0 10px;
  }
  .tabs-tabpane-width {
    padding: 0 10px;
  }
}
@media (max-width: 1000px) {
  .bannerAdd {
    background-image: none !important;
  }
  .xmvideo{
    display: none i !important;
  }
}
@media (max-width: 600px) {
  .banner-title {
    width: 100%;
  }

  .card-grid div {
    width: 100%;
  }
  .kong {
    font-size: 80px;
    margin-top: -40px;
  }
  .banner-left {
    padding-top: 30px !important;
  }
  .banner {
    /* height: auto; */
  }
 
}
@media (max-width: 1270px) {
  /deep/.ant-tabs-top-content {
    padding: 0 20px;
  }
}
</style>
